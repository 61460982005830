import React from "react"
import { FlexRow, FlexCol } from "components/FlexGrid"

import useMedia from "hooks/useMedia.js"
import { GetLocationsData } from "utils/locationsData"
import {
  FindUsModuleContainer,
  Title,
  LocationsContainer,
  MapModule,
  MapImg,
  MapText,
  InnerGrid,
} from "./style"

const FindUsModule = () => {
  const locationsData = GetLocationsData()
  const isMobile = useMedia(
    // Media queries
    ["(max-width: 767px)"],
    //options
    [true],
    // default
    false
  )

  return (
    <FindUsModuleContainer gap={[20, 30, 45, 60]}>
      <FlexRow>
        <FlexCol xs={12} sm={3}>
          <Title>Find us</Title>
        </FlexCol>
        <FlexCol xs={12} sm={9}>
          <LocationsContainer>
            <p>
              We are based in Auckland, yet we work with clients from all over
              the world. Whether you have a project you'd like to discuss, or
              you'd just like to find out more about how we work, please give us
              a call or send us an email.
            </p>

            <InnerGrid fullWidth gap={[0, 30, 45, 60]}>
              <FlexRow>
                {locationsData.map((location, index) => {
                  return (
                    <FlexCol key={index} xs={12}>
                      <MapModule first={index === 0}>
                        <MapImg fluid={location.img} />
                        <MapText>
                          <h4>{location.title}</h4>
                          <p
                            className="address"
                            dangerouslySetInnerHTML={{
                              __html: location.address,
                            }}
                          />
                          <span className="name">{location.person}</span>
                          <div className="number">
                            <span>M</span>
                            {isMobile ? (
                              <a href={`tel:${location.mobile}`}>
                                {location.mobile}
                              </a>
                            ) : (
                              <p>{location.mobile}</p>
                            )}
                          </div>

                          <div className="number">
                            <span>P</span>
                            {isMobile ? (
                              <a href={`tel:${location.telephone}`}>
                                {location.telephone}
                              </a>
                            ) : (
                              <p>{location.telephone}</p>
                            )}
                          </div>
                          <a
                            className="email"
                            href={`mailto:${location.email}`}
                          >
                            Email {location.person.split(" ", 2)[0]}
                          </a>
                        </MapText>
                      </MapModule>
                    </FlexCol>
                  )
                })}
              </FlexRow>
            </InnerGrid>
          </LocationsContainer>
        </FlexCol>
      </FlexRow>
    </FindUsModuleContainer>
  )
}

export default FindUsModule
