import styled from "styled-components"
import { device } from "../../utils/breakpoints.js"
import { FlexGrid, FlexCol, FlexRow } from "../FlexGrid"

import { Teaser } from "../Teaser"
import {
  ImageContainer,
  InfoContainer,
  Title,
  Excerpt,
  NewButton,
} from "../Teaser/style"

export const NewFlexGrid = styled(FlexGrid)`
  position: relative;

  @media ${device.tablet} {
    max-width: 1440px !important;
    width: 100% !important;
  }
`

export const NewFlexRow = styled(FlexRow)`
  @media ${device.tablet} {
    margin: 0 !important;
  }
`
export const NewFlexCol = styled(FlexCol)`
  padding-right: 0.2rem;

  @media ${device.tablet} {
    margin-bottom: 46px !important;
    padding: 0 !important;
  }
`
export const NewTeaser = styled(Teaser)`
  border: none;
  position: relative;
  padding-bottom: 40px;

  @media ${device.tablet} {
    display: flex;
    flex-direction: ${props => (props.child % 2 ? "row-reverse" : "row")};
    align-items: none;
    border-bottom: none;
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
  }

  ${ImageContainer} {
    position: absolute;
    width: calc(100% + 60px);
    margin-left: -30px;

    @media ${device.tablet} {
      width: 50%;
      position: relative;
      padding-top: 35%;
      margin-left: 0;
    }
  }
  ${InfoContainer} {
    padding-top: calc(58% + 25px);
    padding-right: 0;

    @media (min-width: 500px) {
      padding-top: calc(58% + 35px);
    }

    @media ${device.tablet} {
      width: 50%;
      padding-top: ${props => (props.child % 2 ? "30px" : "30px")};
      padding-left: ${props => (props.child % 2 ? "30px" : "30px")};
      padding-right: 30px;
    }
    @media ${device.desktop} {
      width: 50%;
      padding-top: 50px;
      padding-left: 50px;
      padding-right: 30px;
    }

    @media ${device.large} {
      width: 50%;
      padding-top: ${props => (props.child % 2 ? "62px" : "115px")};
      padding-left: ${props => (props.child % 2 ? "47px" : "120px")};
      padding-right: 120px;
    }

    svg {
      position: absolute;
      transition: 420ms;
      bottom: -10px;
      left: 0;
      width: 36px;
      height: 36px;
      transform: scale(1);

      path {
        fill: #00ff00;
      }

      @media ${device.tablet} {
        bottom: 0;
        left: 0;
        width: 41px;
        height: 40px;
      }
    }

    :hover {
      svg {
        transform: scale(1.12);
        path {
          fill: #0000ff;
        }
        circle {
          stroke: #0000ff;
        }
      }
    }
  }

  ${Title} {
    font-weight: 700;
    font-size: 32px;
    color: #0000ff;
    letter-spacing: -0.25px;
    line-height: 36px;
    margin-bottom: 22px;

    @media ${device.desktop} {
      font-size: 48px;
      letter-spacing: -0.38px;
      line-height: 54px;
      margin-bottom: ${props => (props.child % 2 ? "35px" : "40px")};
    }
  }

  ${Excerpt} {
    font-weight: 400;
    font-size: 12px;
    color: #9e9da8;
    letter-spacing: 0.15px;
    line-height: 18px;
    margin-bottom: 10px;

    @media ${device.tablet} {
      font-size: 14px;
      letter-spacing: 0.17px;
      line-height: 20px;
      margin-bottom: 20px;
    }
    @media ${device.desktop} {
      margin-bottom: 30px;
    }
    @media ${device.large} {
      margin-bottom: 40px;
    }
  }

  ${NewButton} {
    position: relative;
    margin-top: 0;
  }
`