import styled from "styled-components"
import { device } from "breakpoints"
import { FlexGrid } from "components/FlexGrid"
import { Teaser } from "components/Teaser"
import { Title, ImageContainer, Excerpt } from "components/Teaser/style"

export const ThreeColumnFlexGrid = styled(FlexGrid)``

export const ThreeColumnTeaser = styled(Teaser)`
  ${ImageContainer} {
    margin-bottom: 25px;
    padding-top: 65.78%;

    @media ${device.tablet} {
      margin-bottom: 17px;
    }

    @media ${device.desktop} {
      margin-bottom: 30px;
    }
  }

  ${Title} {
    color: ${props => props.theme.blue};
    font-size: 16px;
    margin-bottom: 0;

    @media ${device.tablet} {
      font-size: 20px;
      margin-bottom: 7px;
    }
    @media ${device.desktop} {
      font-size: 28px;
      margin-bottom: 10px;
    }
  }
  ${Excerpt} {
    display: none;

    @media ${device.tablet} {
      display: block;
      margin-bottom: 0;
      color: #9e9da8;
      font-size: 12px;

      font-weight: 500;
    }

    @media ${device.desktop} {
      font-size: 14px;
    }
  }
`
