import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { ThreeColumnGrid } from "components/ThreeColumnGrid"
import { TeaserBig } from "../components/Teaser_Big"
import { StoriesModule } from "../components/StoriesModule"
import { FindUsModule } from "components/FindUsModule"

import { Stories2ndRow } from "components/Stories2ndRow"

const IndexPage = ({ pageContext }) => {
  const { stories } = pageContext

  return (
    <Layout
      internal={false}
      title={"Great brands start here..."}
      home
      headerImg={pageContext.header.headerImage}
      excerpt={pageContext.textOptions.introStatement}
    >
      <SEO />
      <TeaserBig pageContext={pageContext} />
      <ThreeColumnGrid pageContext={pageContext} />

      {stories && stories.stories.length > 0 && (
        <>
          <StoriesModule story={stories.stories[0]} />
          <Stories2ndRow stories={stories.stories.slice(1)} />
        </>
      )}
      <FindUsModule />
    </Layout>
  )
}

export default IndexPage
