import React from "react"
import { getTags } from "../../utils/helpers"
import { NewTeaser, NewFlexCol, NewFlexGrid, NewFlexRow } from "./style.js"

const TeaserLarge = ({ pageContext }) => {
  const elements = pageContext.caseStudies.caseStudies.slice(0, 5)

  return (
    <>
      {elements.map((element, index) => {
        if (!element) return false

        const img =
          element?.homePageOptions?.homePageThumbnail || element.featuredImage

        return (
          <NewFlexGrid gap={[20]} key={index} id="discover-here">
            <NewFlexRow>
              <NewFlexCol xs={12}>
                <NewTeaser
                  title={element.textOptions.homeStatement || element.title}
                  excerpt={getTags(element.categories.nodes)}
                  withButton
                  iconName={"right-arrow"}
                  img={{ ...img, sizes: "(min-width: 1024px) 700px, 100vw" }}
                  child={index}
                  titleLink={element.link}
                />
              </NewFlexCol>
            </NewFlexRow>
          </NewFlexGrid>
        )
      })}
    </>
  )
}

export default TeaserLarge
