import React from "react"
import { getTags } from "../../utils/helpers"
import { ThreeColumnFlexGrid, ThreeColumnTeaser } from "./style"
import { FlexRow, FlexCol } from "components/FlexGrid"

const ThreeColumnGrid = ({ pageContext }) => {
  const elements = pageContext.caseStudies.caseStudies.slice(5, 11)

  return (
    <ThreeColumnFlexGrid rowGap={[23, 20, 30, 45]}>
      <FlexRow>
        {elements.map((element, index) => {

          if(!element) return false;
          const img =
            element?.homePageOptions?.homePageThumbnail || element.featuredImage
          
          return (
            <FlexCol key={index} xs={6} sm={4} md={4}>
              <ThreeColumnTeaser
                title={element.title}
                excerpt={getTags(element.categories.nodes)}
                titleLink={element.link}
                img={{ ...img, sizes: "(min-width: 1024px) 370px, 100vw" }}
              />
            </FlexCol>
          )
        })}
      </FlexRow>
    </ThreeColumnFlexGrid>
  )
}

export default ThreeColumnGrid
